import string from "underscore.string";

/**
 * Encapsulating page data
 */
class PagePost {
    constructor(pageMeta, siteMeta) {
        this._pageMeta = pageMeta;
        this._siteMeta = siteMeta;
    }

    get type() {
        return 'page';
    }

    get description() {
        return this._pageMeta.description;
    }

    get excerpt() {
        return null;
    }

    get link() {
        return this._pageMeta.link;
    }

    get seoDescription() {
        return string.stripTags(this.description);
    }

    get seoFeaturedImage() {
        return this._pageMeta.image || this._siteMeta.cover;
    }

    get title() {
        return this._pageMeta.title;
    }

    get truncatedExcerpt() {
        return null;
    }

    get robots() {
        return this._pageMeta.robots;
    }

    get category() {
        return this._pageMeta.category;
    }

    get author() {
        return 'Daniel Wesley';
    }

    get authorPhoto() {
        return 'https://www.gravatar.com/avatar/aa69a42d52ff2f98c9b7f23b9eff65c2?s=80&d=https%3A%2F%2Fcardguru.com%2Fassets%2Fimages%2Fdefault-author-photo.png';
    }

    get showAdvertiserDisclosure() {
        return this._pageMeta.showAdvertiserDisclosure;
    }

    get date() {
        return this._pageMeta.published || null;
    }

    get published() {
        return this._pageMeta.published || null;
    }

    get modified() {
        return this._pageMeta.modified || null;
    }
}

export default PagePost;
